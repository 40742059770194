import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoading } from "src/modules/notification/redux";
import CompanyAssignedTableComponent from './companyAssignedTable.component';
import { fetchCompanyAssignedTable } from '../../redux/action/dashboard.action';

function CompanyAssignedTableContainer() {
  const isLoading = useSelector((state) =>
    selectLoading(state, fetchCompanyAssignedTable.typePrefix)
  );
  const generateRecord = useSelector((state) =>
    state.dashboard.companyAssignedRecords
  );

  return (
    <CompanyAssignedTableComponent
      isLoading={isLoading}
      generateRecord={generateRecord}
    />
  );
}

export default CompanyAssignedTableContainer;
