import { combineReducers } from "redux";
import { companyUsersReducer } from "src/modules/companies/redux";
import { notificationReducer } from "src/modules/notification/redux";
import { emailLogsReducer } from "src/modules/email-log/redux/email-log.slice";
import { alertsReducer } from "../redux/alerts/alerts.slice";
import { loadingReducer } from "../redux/loading/loading.slice";
import { constantsReducer } from "../redux/constants/constants.slice";
import { sessionReducer } from "../redux/session/session.slice";
import { approvalsReducer } from "../../modules/approvals/redux/slice";
import { companiesReducer } from "../../modules/companies/redux/slice";
import { disbursementReducer } from "../../modules/consumer-engagement/redux/slice";
import { partnersAndResellersReducer } from "../../modules/partners-and-resellers/redux/partners-and-resellers.slice";
import { dashboardReducer } from "../../modules/dashboard/redux/slice/dashboard.slice";
import { masterControlReducer } from "../../modules/master-control/redux/master-control.slice";

const rootReducer = combineReducers({
  alerts: alertsReducer,
  loading: loadingReducer,
  constants: constantsReducer,
  session: sessionReducer,
  approvals: approvalsReducer,
  companies: companiesReducer,
  disbursement: disbursementReducer,
  companyUsers: companyUsersReducer,
  partnersAndResellers: partnersAndResellersReducer,
  dashboard: dashboardReducer,
  notification: notificationReducer,
  emailLog: emailLogsReducer,
  masterControl: masterControlReducer,
});

export default rootReducer;
