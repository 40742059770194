import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin

const PieChartScanValidityComponent = ({
  data,
  isLoading,
  error,
}) => {
  const totalScanCount = data ? data.totalCount : 0;
  const totalSuspendedCount = data ? data.totalSuspended : 0;
  const pieChartData = {
    labels: ['Total Scan Count', 'Suspended Count'],
    datasets: [
      {
        data: [totalScanCount, totalSuspendedCount],
        backgroundColor: ['rgb(75, 192, 192)', 'rgb(255, 99, 132)'],
      },
    ],
  };

  const renderContent = () => {
    if (isLoading) {
      return <div>Loading...</div>; // Render a loading indicator
    }

    if (error) {
      return (
        <div>
          Error:
          {' '}
          {error}
        </div>
      ); // Render an error message
    }

    return (
      <>
        <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>Scan Count Statistics</Typography>
        <Box style={{ maxWidth: '300px', margin: '0 auto' }}>
          <Doughnut
            data={pieChartData}
            options={{
              plugins: {
                datalabels: { // Enable the datalabels plugin for Doughnut chart only
                  display: true,
                  color: 'white', // Set the font color of the data labels
                },
              },
            }}
            plugins={[ChartDataLabels]} // Register the datalabels plugin for the Doughnut chart
          />
        </Box>
      </>
    );
  };

  return (
    <Box sx={{
      backgroundColor: '#ffffff', p: 2, borderRadius: '12px', padding: '30px',
    }}
    >
      {renderContent()}
    </Box>
  );
};

export default PieChartScanValidityComponent;
