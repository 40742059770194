import axios from 'axios';

export default function getApprovals({ page, search, length }) {
  const start = page * length;
  const promise = new Promise((resolve, reject) => axios
    .get('/account/system-admin/api/v1/companies',
      {
        params: {
          start,
          length,
          search,
        },
      })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.message)));

  return promise;
}
