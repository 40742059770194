import React from 'react';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton
} from '@material-ui/core';

export default function CompanyGenerateTableComponent({
  isLoading,
  generateRecord,
}) {
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        maxHeight: '500px',
        overflow: 'auto',
        p: 2,
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>Company Total Assigned QR Count</Typography>
      <TableContainer component={Box} border={1} borderColor="grey.500" borderRadius={1} p={1}>
        {isLoading ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Company</TableCell>
                <TableCell align="center">QR Generated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generateRecord.map((record, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{record.name}</TableCell>
                  <TableCell align="center">{record.recordsTotal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
}
