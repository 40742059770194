import React from 'react';
import { useSelector } from 'react-redux';
import { fetchPieChartScanValidity } from 'src/modules/dashboard/redux/action/dashboard.action';
import { selectLoading } from 'src/modules/notification/redux';
import PieChartScanValidityComponent from './pieChartScanValidity.component';

const PieChartScanValidityContainer = () => {
  const pieChartData = useSelector((state) => state.dashboard.pieChartData);
  const isLoading = useSelector((state) => selectLoading(state, fetchPieChartScanValidity.typePrefix));
  const error = useSelector((state) => state.dashboard.pieChartData.error);

  return (
    <PieChartScanValidityComponent
      data={pieChartData}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default PieChartScanValidityContainer;
