import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoading, addAlert } from "src/modules/notification/redux";
import CompanyGenerateTableComponent from './companyGenereateTable.component';
import { fetchCompanyGenerateTable } from '../../redux/action/dashboard.action';

function CompanyGenerateTableContainer() {
  const isLoading = useSelector((state) =>
    selectLoading(state, fetchCompanyGenerateTable.typePrefix)
  );
  const generateRecord = useSelector((state) =>
    state.dashboard.companyGenerateRecords
  );

  return (
    <CompanyGenerateTableComponent
      isLoading={isLoading}
      generateRecord={generateRecord}
    />
  );
}

export default CompanyGenerateTableContainer;
