import { Navigate } from "react-router-dom";
import Logout from "src/modules/logout";
import MainLayout from "../../components/MainLayout";
import Login from "../../modules/login/pages/main";
import Auth from "../../modules/auth/pages/main";
import NotFound from "../../modules/not-found/pages/main";
import DashboardLayout from "../../components/DashboardLayout";
import Dashboard from "../../modules/dashboard/pages/main";
import Approvals from "../../modules/approvals/pages/main";
import Companies from "../../modules/companies/pages/main";
import PartnersAndResellers from "../../modules/partners-and-resellers/pages/main";
import EmailLog from "../../modules/email-log/pages/main";
import MasterControl from "../../modules/master-control/pages/main";
import ConsumerEngagement from "../../modules/consumer-engagement/pages/main";

const routes = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "auth", element: <Auth /> },
      { path: "", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "admin",
    element: <DashboardLayout />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "approvals", element: <Approvals /> },
      { path: "companies", element: <Companies /> },
      { path: "consumer-engagement", element: <ConsumerEngagement /> },
      { path: "partners-and-resellers", element: <PartnersAndResellers /> },
      { path: "email-log", element: <EmailLog /> },
      { path: "master-control", element: <MasterControl /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  { path: "logout", element: <Logout /> },
  { path: "404", element: <NotFound /> },
];

export default routes;
