import { Helmet } from "react-helmet";
import {
  Box,
  Paper,
  InputBase,
  Container,
  Card,
  Typography,
  TableCell,
  Button,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from "prop-types";
import DataTable from "../../../../components/DataTable";

const style = (theme) => ({
  paper: {
    backgroundColor: "#3A4D54",
    color: theme.palette.common.white,
    borderRadius: "4px"
  },
  boxSubmenu: {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "8px",
      right: "-15px",
      border: "8px solid",
      borderColor: "transparent transparent transparent",
      borderLeftColor: "#3A4D54",
      zIndex: 1
    }
  },
  menuItem: {
    fontSize: "small",
    display: "block"
  },
});

const useStyles = makeStyles(style);

export default function CompaniesPage({
  industries,
  isCompaniesLoading,
  companies,
  totalCompanies,
  companiesPage,
  onAccessRightClick,
  onCompanyViewClick,
  onCompanyWhiteLabelClick,
  onCompanyManageBranchClick,
  onCompaniesPageClick,
  onCompanySwapPlanClick,
  onSerialNumberAssignClick,
  onEnableClick,
  onDisableClick,
  allBranchWhiteLabelCount,
  open,
  anchorEl,
  anchorRef,
  handleOpen,
  handleClose,
  selectedRow,
  setSelectedRow,
  //  👇  Companies Search Function  👇  //
  query,
  onSearchPress,
  onSearchSubmit,
  //  👇  Company Users Dialog  👇  //
  onCompanyUsersViewClick,
  handleItemPerPageChange,
  itemPerPage,
}) {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Companies | Kood Asia Management Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          paddingTop: 3,
          paddingBottom: 12,
        }}
      >
        <Container maxWidth={false}>
          <Card
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                padding: 2,
              }}
            >
              Companies
            </Typography>
            <Paper
              component="form"
              onSubmit={onSearchSubmit}
              sx={{
                p: "2px 6px",
                display: "flex",
                alignItems: "center",
                width: 300,
              }}
            >
              <InputBase
                sx={{ flex: 1 }}
                placeholder="Search..."
                onChange={onSearchPress}
                value={query}
              />
              <Button
                sx={{
                  fontSize: "1.5rem",
                  backgroundColor: "#d7d7d7",
                  ":hover": {
                    bgcolor: "primary.main",
                    color: "white",
                  },
                }}
                type="button"
                aria-label="search"
                onClick={onSearchSubmit}
              >
                🔍︎
              </Button>
            </Paper>
          </Card>
          <DataTable
            isLoading={isCompaniesLoading}
            rows={companies}
            rowsPerPage={itemPerPage}
            total={totalCompanies}
            page={companiesPage}
            renderHeader={() => (
              <>
                <TableCell>Registration Number</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>White Label</TableCell>
                <TableCell>Branch White Label Count</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </>
            )}
            renderRow={(row, index) => {
              const actionItems = [
                {
                  label: 'Enable',
                  condition: row.company.status === 'deactivated',
                  onClick: () => onEnableClick(selectedRow.company.id),
                },
                {
                  label: 'Disable',
                  condition: row.company.status === 'approved',
                  onClick: () => onDisableClick(selectedRow.company.id),
                },
                {
                  label: 'Access Rights',
                  onClick: () => onAccessRightClick(selectedRow),
                },
                {
                  label: 'View',
                  onClick: () => onCompanyViewClick(selectedRow),
                },
                {
                  label: 'White Label',
                  onClick: () => onCompanyWhiteLabelClick(selectedRow),
                },
                {
                  label: 'Manage Branch',
                  onClick: () => onCompanyManageBranchClick(selectedRow),
                },
                {
                  label: 'Users',
                  onClick: () =>
                    onCompanyUsersViewClick({
                      companyId: selectedRow.company.id,
                      companyPlan: selectedRow.company.plan,
                    }),
                },
                {
                  label: 'Swap Plan',
                  onClick: () => onCompanySwapPlanClick(selectedRow),
                  condition: row.company.plan.includes("Free Saas"),
                },
                {
                  label: 'Assign',
                  onClick: () =>
                    onSerialNumberAssignClick({
                      companyUuid: selectedRow.company.id,
                      company: selectedRow,
                    }),
                },
              ];

              return (
                <>
                  <TableCell>{row.company.regNo}</TableCell>
                  <TableCell>{row.company.name}</TableCell>
                  <TableCell>{row.company.whiteLabel}</TableCell>
                  <TableCell>{allBranchWhiteLabelCount[index]}</TableCell>
                  <TableCell
                    sx={{
                      color:
                        row.company.plan.includes("Full Saas") &&
                        !["Pro", "Biz", "Lite"].includes(row.company.plan) &&
                        "red",
                    }}
                  >
                    {row.company.plan}
                  </TableCell>
                  <TableCell>{row.company.status}</TableCell>
                  {/* Action button starts here */}
                  <TableCell>
                    <Button
                      onClick={(event) => {
                        handleOpen(event);
                        setSelectedRow(row);
                      }}
                    >
                      <MoreHorizIcon />
                    </Button>
                    <Popper
                      open={open && selectedRow.company.id === row.company.id}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      placement="left-start"
                      style={{ zIndex: 1 }}
                    >
                      <Box className={classes.boxSubmenu}>
                        <Paper className={classes.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                              {actionItems.map((item) => (
                                item.condition !== undefined ? item.condition && (
                                  <MenuItem
                                    className={classes.menuItem}
                                    key={row}
                                    onClick={(e) => {
                                      item.onClick();
                                      handleClose(e);
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    className={classes.menuItem}
                                    key={row}
                                    onClick={(e) => {
                                      item.onClick();
                                      handleClose(e);
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                )
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Box>
                    </Popper>
                  </TableCell>
                </>
              );
            }}
            onPageClick={onCompaniesPageClick}
            handleItemPerPageChange={handleItemPerPageChange}
          />
        </Container>
      </Box>
    </>
  );
}

CompaniesPage.propTypes = {
  industries: PropTypes.array.isRequired,
  isCompaniesLoading: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  companiesPage: PropTypes.number.isRequired,
  onCompanyViewClick: PropTypes.func.isRequired,
  onCompanyWhiteLabelClick: PropTypes.func.isRequired,
  onCompanyManageBranchClick: PropTypes.func.isRequired,
  onCompaniesPageClick: PropTypes.func.isRequired,
  allBranchWhiteLabelCount: PropTypes.array.isRequired,
};
