import React from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import ClusterImage from '../../assets/img/Eo_circle_green_blank.svg.png';

const MapScanCountComponent = ({
  onLoad,
  isLoading,
  isLoaded,
  center,
  onUnmount,
  data,
}) => {
  const clusterStyles = [
    {
      url: ClusterImage,
      textColor: '#FFFFFF',
      textSize: 12,
      width: 40,
      height: 40,
    },
  ];

  return (
    <div style={{ height: '340px', width: '100%' }}>
      {isLoading && <div>Loading...</div>}
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '340px' }}
          onLoad={onLoad}
          center={center}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: true,
            streetViewControl: true,
            rotateControl: true,
            clickableIcons: false,
            keyboardShortcuts: false,
          }}
        >
          <MarkerClusterer
            options={{
              styles: clusterStyles,
              maxZoom: 14, // The maximum zoom level where clustering should occur
            }}
          >
            {(clusterer) => data.map((scan) => (
              <Marker
                key={scan.id}
                clusterer={clusterer}
                position={{ lat: parseFloat(scan.latitude), lng: parseFloat(scan.longitude) }}
              />
            ))}
          </MarkerClusterer>
        </GoogleMap>
      )}
    </div>
  );
};

export default MapScanCountComponent;
