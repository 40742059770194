import {
  Box,
  Button,
  Input,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';

const useStyle = makeStyles((theme) => ({
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingRight: '20px',
    paddingLeft: '20px',
    marginBottom: '12px',
  },
  filterFieldContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  inputRootProduct: {
    paddingTop: '8px !important',
    paddingBottom: '7px !important',
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
  },
  paperRoot: {
    width: '217px',
  },
}));

export default function SearchComponent({
  searchPlaceholder = 'Search',
  handleSearch,
  clearSearch,
  isFetching,
  hasFilter = true,
  toggleFilterOpen,
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          searchValue: '',
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={searchPlaceholder}
                sx={{ mr: 1, mb: 1, mt: 1 }}
                disableUnderline="true"
                classes={classes.inputRootProduct}
                inputProps={{
                  disableUnderline: true,
                  margin: 'none',
                  style: {
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    borderRadius: '4px',
                    backgroundColor: '#eceff0',
                  },
                }}
                {...formik.getFieldProps('searchValue')}
              />
              {hasFilter && (
                <Button
                  sx={{
                    mr: 1,
                    mb: 1,
                    mt: 1,
                    pb: 0.5,
                  }}
                  variant="outlined"
                  size="small"
                  disableElevation
                  onClick={toggleFilterOpen}
                >
                  Filter
                </Button>
              )}
              <Button
                sx={{
                  mr: 1,
                  mb: 1,
                  mt: 1,
                  pb: 0.5,
                }}
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={clearSearch}
              >
                Clear
              </Button>
              <Button
                sx={{
                  mr: 1,
                  mb: 1,
                  mt: 1,
                  pb: 0.5,
                }}
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                Search
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

SearchComponent.propTypes = {
  searchPlaceholder: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFilter: PropTypes.bool.isRequired,
  toggleFilterOpen: PropTypes.func.isRequired,
};
