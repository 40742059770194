import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from "@material-ui/core";

const QrGeneratedAssignedGraphCardComponent = ({ data, isLoading, error }) => {
  // Define a set of 50 constant visible colors
  const constantVisibleColors = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(153, 102, 255)',
    'rgb(255, 159, 64)',
    'rgb(255, 102, 102)',
    'rgb(255, 204, 102)',
    'rgb(102, 255, 102)',
    'rgb(102, 102, 255)',
    'rgb(255, 51, 51)',
    'rgb(255, 153, 51)',
    'rgb(0, 255, 0)',
    'rgb(0, 0, 255)',
    'rgb(255, 51, 153)',
    'rgb(255, 153, 255)',
    'rgb(51, 153, 255)',
    'rgb(204, 0, 0)',
    'rgb(204, 102, 0)',
    'rgb(0, 204, 0)',
    'rgb(0, 0, 204)',
    'rgb(204, 51, 102)',
    'rgb(204, 102, 153)',
    'rgb(102, 204, 153)',
    'rgb(153, 0, 0)',
    'rgb(153, 76, 0)',
    'rgb(0, 153, 0)',
    'rgb(0, 0, 153)',
    'rgb(153, 38, 76)',
    'rgb(153, 76, 114)',
    'rgb(76, 153, 114)',
    'rgb(102, 0, 0)',
    'rgb(102, 51, 0)',
    'rgb(0, 102, 0)',
    'rgb(0, 0, 102)',
    'rgb(102, 26, 51)',
    'rgb(102, 51, 76)',
    'rgb(51, 102, 76)',
    'rgb(51, 0, 0)',
    'rgb(51, 25, 0)',
    'rgb(0, 51, 0)',
    'rgb(0, 0, 51)',
    'rgb(51, 0, 102)',
    'rgb(51, 0, 51)',
    'rgb(51, 51, 0)',
    'rgb(0, 51, 102)',
    'rgb(0, 51, 51)',
    'rgb(0, 102, 51)',
  ];

  // Generate the x-axis labels
  const generateXAxisLabels = () => Object.keys(data).map((item) => {
    const date = new Date(item);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day} ${month}`;
  });

  // Generate the datasets for line chart
  const generateLineChartDatasets = () => {
    const lineDatasets = [];
    const barDatasets = [];

    let companies = data[Object.keys(data)[0]];
    if (companies) {
      companies = Object.keys(companies);
    } else {
      return [];
    }

    const getNextVisibleColor = (() => {
      let index = 0;

      return () => {
        index = (index + 1) % constantVisibleColors.length;
        return constantVisibleColors[index];
      };
    })();

    companies.forEach((company) => {
      const companyLineData = [];
      const companyBarData = [];
      Object.keys(data).forEach((key1) => {
        const date = data[key1];
        const companyData = date[company];

        companyLineData.push(companyData.generated);
        companyBarData.push(companyData.assigned);
      });

      lineDatasets.push({
        type: 'line',
        label: `${company} Generated Count`,
        data: companyLineData,
        fill: false,
        borderColor: getNextVisibleColor(),
        tension: 0.1,
      });

      barDatasets.push({
        type: 'bar',
        label: `${company} Assigned Count`,
        data: companyBarData,
        backgroundColor: getNextVisibleColor(),
        stack: 'stack1',
        yAxisID: 'suspended-y-axis',
      });
    });

    return [...lineDatasets, ...barDatasets];
  };

  const combinedChartData = {
    labels: generateXAxisLabels(),
    datasets: generateLineChartDatasets(),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 5,
        ticks: {
          precision: 0,
        },
        title: {
          display: true,
          text: 'Total Generate Count',
        },
        grid: {
          borderColor: 'rgb(75, 192, 192)',
        },
      },
      'suspended-y-axis': {
        position: 'right',
        beginAtZero: true,
        suggestedMax: 5,
        ticks: {
          precision: 0,
        },
        title: {
          display: true,
          text: 'Total Assigned Count',
        },
        grid: {
          borderColor: 'rgb(255, 99, 132)',
        },
      },
      x: {
        title: {
          min: 5,
          display: true,
          text: 'Date',
        },
        grid: {
          borderColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: 'legend-container2',
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        filter: (tooltipItem) => tooltipItem.raw > 0,
      },
    },
  };

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'column';
      listContainer.style.margin = 0;
      listContainer.style.marginLeft = '16px';
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginTop = '10px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderStyle = 'solid';
        boxSpan.style.borderWidth = '3px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = 0;
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  };
  return (
    <Box>
      <h3 style={{ fontSize: '24px', fontWeight: 'bolder' }}>Total Scan Count and Suspended Count</h3>
      <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80vh' }}>
        {error ? (
          <Box width="100%" height="100%">
            <h4 style={{ fontSize: '24px', fontWeight: 'bolder' }}>Error</h4>
          </Box>
        ) : isLoading ? (
          <Box width="100%" height="100%">
            <h4 style={{ fontSize: '24px', fontWeight: 'bolder' }}>Loading</h4>
          </Box>
        ) : (
          <>
            <Box flexGrow={1} height="100%">
              <Line data={combinedChartData} options={chartOptions} plugins={[htmlLegendPlugin]} />
            </Box>
            <Box id="legend-container2" style={{ width: "300px", height: "100%", overflowY: "auto" }} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default QrGeneratedAssignedGraphCardComponent;
