import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, addAlert } from "src/modules/notification/redux";
import {
  putWhiteLabel,
  getCompanies,
  setCompanyStatus,
  removeCompanyWhiteLabel,
  clearCompanies,
  clearCompanyUsers,
  clearReassignResult,
  getCompanyUsers,
} from "../../redux";
import CompaniesPage from "./companies.page";
import CompanyDialog from "../../components/dialogs/company-dialog";
import CompanyWhiteLabelDialog from "../../components/dialogs/company-white-label-dialog";
import CompanyUsersDialog from "../../components/dialogs/company-users";
import CompanyAccessRightDialog from "../../components/dialogs/company-access-right-dialog";
import CompanySwapPlanDialog from "../../components/dialogs/company-plan-swap-dialog";
import CompanyManageBranchDialog from "../../components/dialogs/company-manage-branch-dialog";
import CompanySerialNumberAssignDialog from "../../components/dialogs/company-assign-sn-dialog";
import CompanyChangeStatusDialog
  from "../../components/dialogs/company-change-status-dialog/companyChangeStatusDialog.container";
import ReassignResultDialog from "../../components/dialogs/reassign-result-dialog";

export default function CompaniesContainer() {
  const dispatch = useDispatch();

  const industries = useSelector((state) => state.constants.industries);
  const isLoading = useSelector((state) =>
    selectLoading(state, getCompanies.typePrefix)
  );
  const companies = useSelector((state) => state.companies.companies);
  const companiesPage = useSelector((state) => state.companies.companiesPage);
  const totalCompanies = useSelector((state) => state.companies.totalCompanies);
  const allBranchWhiteLabelCount = useSelector((state) => state.companies.allBranchWhiteLabelCount);
  const reassignResult = useSelector((state) => state.companies.reassignResult);
  const [selectedSwapPlanCompany, setSelectedSwapPlanCompany] = useState(null);
  const [selectedAccessCompany, setSelectedAccessCompany] = useState(null);
  const [selectedViewCompany, setSelectedViewCompany] = useState(null);
  const [selectedWhiteLabelCompany, setSelectedWhiteLabelCompany] =
    useState(null);
  const [selectedManageBranch, setSelectedManageBranch] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [query, setQuery] = useState("");

  const [changeStatusDialog, setChangeStatusDialog] = useState('hidden');
  const [selectedChangeStatusCompany, setSelectedChangeStatusCompany] = useState(null);

  const [selectedCompanyForUsers, setSelectedCompanyForUsers] = useState(null);
  const [selectedSerialNumberAssignCompany, setSelectedSerialNumberAssignCompany] = useState(null);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchData = () => {
    dispatch(
      getCompanies({
        page: currentPage,
        length: itemPerPage,
        searchQuery: query,
      })
    );
  };

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(clearCompanies());
    };
  }, [dispatch, itemPerPage]);

  const onSearchSubmit = (event) => {
    event.preventDefault();

    dispatch(
      getCompanies({
        page: 0,
        length: itemPerPage,
        searchQuery: query,
      })
    );
  };

  const onSearchPress = (event) => {
    setQuery(event.target.value);

    if (event.key === "Enter") {
      onSearchSubmit(event);
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAccessRightClick = (company) => {
    setSelectedAccessCompany(company);
  };

  const onCompanyViewClick = (company) => {
    setSelectedViewCompany(company);
  };

  const onCompanyDialogCloseClick = () => {
    setSelectedViewCompany(null);
  };

  const onCompanyWhiteLabelClick = (company) => {
    setSelectedWhiteLabelCompany(company);
  };

  const onCompanyManageBranchClick = (company) => {
    setSelectedManageBranch(company);
  };

  const onCompanyManageBranchDialogCloseClick = () => {
    setSelectedManageBranch(null);
  };

  const onCompanyWhiteLabelDialogSubmitClick = (values, formik) => {
    dispatch(
      putWhiteLabel({
        id: selectedWhiteLabelCompany.company.id,
        whiteLabel: values.whiteLabel,
      })
    ).then(() => {
      dispatch(
        addAlert({
          severity: "success",
          message: "Company's white label updated successfully",
        })
      );
      setSelectedWhiteLabelCompany(null);
      dispatch(getCompanies({ page: 0, length: itemPerPage }));
    });
  };

  const onRemoveCompanyWhiteLabelClick = () => {
    dispatch(
      removeCompanyWhiteLabel({
        id: selectedWhiteLabelCompany.company.id,
      })
    ).then(() => {
      dispatch(
        addAlert({
          severity: "success",
          message: "Company's white label remove successfully",
        })
      );
      setSelectedWhiteLabelCompany(null);
      dispatch(getCompanies({ page: currentPage, length: itemPerPage }));
    });
  };

  const onCompanyWhiteLabelDialogCloseClick = () => {
    setSelectedWhiteLabelCompany(null);
  };

  const onCompaniesPageClick = (page) => {
    if (companiesPage !== page) {
      setCurrentPage(page);
      dispatch(
        getCompanies({
          page,
          length: itemPerPage,
          searchQuery: query,
        })
      );
    }
  };

  const onCompanyUsersViewClick = async ({
    companyId,
    companyPlan,
    page = 0,
    length = 10,
  }) => {
    setSelectedCompanyForUsers({ companyId, companyPlan });
    await dispatch(
      getCompanyUsers({
        companyId,
        companyPlan,
        page,
        length,
      })
    );
  };

  const onCompanyUsersDialogCloseClick = () => {
    setSelectedCompanyForUsers(null);
    dispatch(clearCompanyUsers());
  };

  const handleItemPerPageChange = (value) => {
    setItemPerPage(value);
  };

  const onCompanyAccessRightDialogCloseClick = () => {
    setSelectedAccessCompany(null);
  };

  const onSerialNumberAssignClick = async ({ company }) => {
    setSelectedSerialNumberAssignCompany(company);
  };

  const onSerialNumberAssignDialogCloseClick = () => {
    setSelectedSerialNumberAssignCompany(null);
  };

  const onEnableClick = (company) => {
    setChangeStatusDialog('enable');
    setSelectedChangeStatusCompany(company);
  };

  const onDisableClick = (company) => {
    setChangeStatusDialog('disable');
    setSelectedChangeStatusCompany(company);
  };

  const onChangeStatus = async (action, remark) => {
    const actionToStatus = {
      enable: 'approved',
      disable: 'deactivated',
    };

    await dispatch(setCompanyStatus({
      id: selectedChangeStatusCompany,
      status: actionToStatus[action],
      remarks: remark,
    }));

    await fetchData();
  };

  return (
    <>
      <CompaniesPage
        industries={industries}
        isCompaniesLoading={isLoading}
        companies={companies}
        totalCompanies={totalCompanies}
        companiesPage={companiesPage}
        onAccessRightClick={onAccessRightClick}
        onCompanyViewClick={onCompanyViewClick}
        onCompanyWhiteLabelClick={onCompanyWhiteLabelClick}
        onCompanyManageBranchClick={onCompanyManageBranchClick}
        onCompaniesPageClick={onCompaniesPageClick}
        onCompanyUsersViewClick={onCompanyUsersViewClick}
        onCompanySwapPlanClick={(row) => setSelectedSwapPlanCompany(row)}
        onSerialNumberAssignClick={onSerialNumberAssignClick}
        query={query}
        onSearchPress={onSearchPress}
        onSearchSubmit={onSearchSubmit}
        handleItemPerPageChange={handleItemPerPageChange}
        itemPerPage={itemPerPage}
        allBranchWhiteLabelCount={allBranchWhiteLabelCount}
        open={open}
        anchorEl={anchorEl}
        handleOpen={handleOpen}
        handleClose={handleClose}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        onEnableClick={onEnableClick}
        onDisableClick={onDisableClick}
      />
      <CompanyDialog
        industries={industries}
        selectedCompany={selectedViewCompany}
        onCompanyDialogCloseClick={onCompanyDialogCloseClick}
      />
      <CompanyWhiteLabelDialog
        selectedCompany={selectedWhiteLabelCompany}
        onCompanyWhiteLabelDialogSubmitClick={
          onCompanyWhiteLabelDialogSubmitClick
        }
        onCompanyWhiteLabelDialogCloseClick={
          onCompanyWhiteLabelDialogCloseClick
        }
        onRemoveCompanyWhiteLabelClick={onRemoveCompanyWhiteLabelClick}
      />
      <CompanyManageBranchDialog
        selectedCompany={selectedManageBranch}
        onCompanyManageBranchDialogCloseClick={onCompanyManageBranchDialogCloseClick}
      />
      {selectedCompanyForUsers && (
        <CompanyUsersDialog
          onCompanyUsersViewClick={onCompanyUsersViewClick}
          selectedCompanyForUsers={selectedCompanyForUsers}
          onCompanyUsersCloseClick={onCompanyUsersDialogCloseClick}
        />
      )}
      <CompanyAccessRightDialog
        selectedCompany={
          selectedAccessCompany ? selectedAccessCompany.company : null
        }
        onCompanyAccessRightDialogCloseClick={() =>
          setSelectedAccessCompany(null)
        }
      />
      <CompanySwapPlanDialog
        selectedCompany={
          selectedSwapPlanCompany ? selectedSwapPlanCompany.company : null
        }
        onCompanySwapPlanDialogCloseClick={(needUpdate) => {
          setSelectedSwapPlanCompany(null);

          if (needUpdate) {
            fetchData();
          }
        }}
      />
      <CompanySerialNumberAssignDialog
        selectedCompany={selectedSerialNumberAssignCompany}
        onSerialNumberAssignDialogCloseClick={onSerialNumberAssignDialogCloseClick}
      />
      <CompanyChangeStatusDialog
        isOpen={changeStatusDialog !== 'hidden'}
        action={changeStatusDialog}
        handleConfirm={async (action, remark) => {
          await onChangeStatus(action, remark);

          setSelectedChangeStatusCompany(null);
          setChangeStatusDialog('hidden');
        }}
        handleClose={() => {
          setSelectedChangeStatusCompany(null);
          setChangeStatusDialog('hidden');
        }}
      />
      {reassignResult && (
        <ReassignResultDialog
          result={reassignResult}
          onClose={() => {
            dispatch(clearReassignResult());
          }}
        />
      )}
    </>
  );
}
