import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLineChartScanCount,
  fetchListingCountryTable,
  fetchMapScanCount,
  fetchPieChartScanValidity,
  getAllCompanies,
  fetchListingCompanyTable,
  fetchTop20Companies,
  fetchCompanyGenerateTable,
  fetchCompanyAssignedTable,
  fetchGeneratedAssignedGraph,
} from '../action/dashboard.action';

const initialState = {
  mapLoaded: false,
  mapScans: {
    data: [],
    isLoading: false,
    error: null,
  },
  lineChartData: {
    data: [],
    isLoading: false,
    error: null,
  },
  generatedAssignedGraph: {
    data: [],
    error: null,
  },
  pieChartData: {
    totalCount: 0,
    totalSuspended: 0,
    isLoading: false,
    error: null,
  },
  table: {
    data: [],
    isLoading: false,
    error: null,
  },
  companyTable: {
    data: [],
    isLoading: false,
    error: null,
  },
  dateRangeFilter: {
    startDate: null,
    endDate: null,
  },
  top20Companies: [],
  companyGenerateRecords: [],
  companyAssignedRecords: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setMapLoaded(state) {
      state.mapLoaded = true;
    },
  },
  extraReducers: {
    // Map Scan, pending, fulfilled, and rejected
    [fetchMapScanCount.pending]: (state) => {
      state.mapScans.isLoading = true;
      state.mapScans.error = null;
    },
    [fetchMapScanCount.fulfilled]: (state, action) => {
      const { payload } = action || {}; // Add a default empty object

      if (payload && payload.data) { // Check if count is an array
        if (Array.isArray(payload.data.Count && payload.data.Suspended)) {
          const tempArray = []; // Initialize tempArray as an empty array

          payload.data.Count.forEach((item) => {
            tempArray.push(item);
          });

          payload.data.Suspended.forEach((item) => {
            tempArray.push(item);
          });

          // update state with tempArray
          state.mapScans.data = tempArray;
        } else {
          state.mapScans.data = [];
        }
      } else {
        state.mapScans.data = [];
      }
      state.mapScans.isLoading = false;
    },
    [fetchMapScanCount.rejected]: (state, action) => {
      state.mapScans.isLoading = false;
      state.mapScans.error = action.error.message;
    },

    // Line Chart, pending, fulfilled, and rejected
    [fetchLineChartScanCount.pending]: (state) => {
      state.lineChartData.isLoading = true;
      state.lineChartData.error = null;
    },
    [fetchLineChartScanCount.fulfilled]: (state, action) => {
      const { payload } = action || {};

      if (payload && payload.data) {
        // Initialize an array to hold data for each date
        const dataArray = [];

        // Iterate through the dates
        Object.keys(payload.data).forEach((date) => {
          const dateData = payload.data[date];

          // Create an object for each date with data for all entities
          const dateDataObject = {
            date,
            ...dateData,
          };

          dataArray.push(dateDataObject);
        });

        state.lineChartData.data = dataArray;
      } else {
        state.lineChartData.data = [];
      }

      state.lineChartData.isLoading = false;
    },
    [fetchLineChartScanCount.rejected]: (state, action) => {
      state.lineChartData.isLoading = false;
      state.lineChartData.error = action.error.message;
    },

    // Table, pending, fulfilled, and rejected
    [fetchListingCountryTable.pending]: (state) => {
      state.table.isLoading = true;
      state.table.error = null;
    },
    [fetchListingCountryTable.fulfilled]: (state, action) => {
      const { payload } = action || {}; // Add a default empty object

      if (payload && Array.isArray(payload.data)) { // Check if payload is an array
        const tempArray = payload.data.map((item) => ({
          country: item.country,
          totalCount: item.total_count,
          totalSuspended: item.total_suspended,
        }));

        state.table.data = tempArray; // Use state.table.data instead of state.table
      } else {
        state.table.data = [];
      }

      state.table.isLoading = false;
    },

    [fetchListingCompanyTable.pending]: (state) => {
      state.companyTable.isLoading = true;
      state.companyTable.error = null;
    },
    [fetchListingCompanyTable.fulfilled]: (state, action) => {
      const { payload } = action || {};

      if (payload && Array.isArray(payload.data)) {
        const tempArray = payload.data.map((item) => ({
          company: item.company,
          totalCount: item.total_count,
          totalSuspended: item.total_suspended,
        }));

        state.companyTable.data = tempArray;
      } else {
        state.companyTable.data = [];
      }

      state.companyTable.isLoading = false;
    },

    // Pie Chart, pending, fulfilled, and rejected
    [fetchPieChartScanValidity.pending]: (state) => {
      state.pieChartData.isLoading = true;
      state.pieChartData.error = null;
    },
    [fetchPieChartScanValidity.fulfilled]: (state, action) => {
      // eslint-disable-next-line camelcase
      const { payload } = action; // Set default value to an empty object

      state.pieChartData.totalCount = payload.data.total_count;
      state.pieChartData.totalSuspended = payload.data.total_suspended;

      state.pieChartData.isLoading = false;
    },
    [fetchPieChartScanValidity.rejected]: (state, action) => {
      state.pieChartData.isLoading = false;
      state.pieChartData.error = action.error.message;
    },
    [getAllCompanies.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      state.totalRecords = payload.recordsTotal;
      state.isLoadingCompanyError = false;
      payload.data.forEach((company) => {
        tempArray.push({
          id: company.id,
          name: company.name,
        });
      });
      state.allCompanies = tempArray;
    },
    [fetchTop20Companies.fulfilled]: (state, action) => {
      const { payload } = action;

      if (payload && Array.isArray(payload)) {
        const tempArray = payload.map((company) => company.id);
        state.top20Companies = tempArray;
      } else {
        state.top20Companies = [];
      }
    },
    [fetchCompanyGenerateTable.fulfilled]: (state, action) => {
      const { payload } = action;
      state.companyGenerateRecords = payload.data;
    },
    [fetchCompanyAssignedTable.fulfilled]: (state, action) => {
      const { payload } = action;
      state.companyAssignedRecords = payload.data;
    },
    [fetchGeneratedAssignedGraph.pending]: (state) => {
      state.generatedAssignedGraph.error = null;
    },
    [fetchGeneratedAssignedGraph.fulfilled]: (state, action) => {
      const { payload } = action;

      state.generatedAssignedGraph.data = payload.data;
    },
    [fetchGeneratedAssignedGraph.rejected]: (state, action) => {
      state.generatedAssignedGraph.error = action.error.message;
    },
  },
});

export const { setMapLoaded, updateDateRangeFilter } = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
