import { useDispatch, useSelector } from 'react-redux';
import EmailLogFilterComponent from './email-log-filter.component';
import {
  setPage,
  setStatusFilter,
  setTypeFilter,
  setDateRangeFilter,
} from '../../redux/email-log.slice';

export default function EmailLogContainer() {
  const isFetching = false;
  const typeList = [
    'distributor_new_downline',
    'distributor_reward_redemption_pending',
    'distributor_recall_upline',
    'distributor_recall_downline',
    'distributor_new_order',
    'distributor_stock_request_pending',
    'distributor_stock_request_accept',
    'distributor_stock_request_reject',
    'distributor_order_cancel',
    'distributor_stockout_pending',
    'distributor_stockreceive_pending',
    'distributor_stockout_cancel',
    'distributor_stockreceive_cancel',
    'distributor_stockout_reject',
    'distributor_stockreceive_reject',
    'distributor_stockout_complete',
    'distributor_stockreceive_success',
    'distributor_reward_redemption_success',
    'distributor_reward_redemption_fail',
    'distributor_account_deactivate',
  ];
  const statusList = [
    'success',
    'failed',
    'sending',
    'pending',
  ];

  const dispatch = useDispatch();
  const filterType = useSelector((state) => state.emailLog.filterType);
  const filterStatus = useSelector((state) => state.emailLog.filterStatus);
  const filterDateRange = useSelector((state) => state.emailLog.filterDateRange);

  const handleFilter = (values) => {
    dispatch(setPage(0));
    dispatch(setTypeFilter(values.type));
    dispatch(setStatusFilter(values.status));
    dispatch(setDateRangeFilter(values.dateRange));
  };

  const handleClear = () => {
    dispatch(setPage(0));
    dispatch(setTypeFilter(''));
    dispatch(setStatusFilter(''));
    dispatch(setDateRangeFilter([]));
  };

  return (
    <EmailLogFilterComponent
      typeList={typeList}
      statusList={statusList}
      isFetching={isFetching}
      filterState={{ type: filterType, status: filterStatus, dateRange: filterDateRange }}
      handleFilter={handleFilter}
      resetFilter={handleClear}
    />
  );
}
