import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoading } from 'src/modules/notification/redux';
import { fetchListingCompanyTable } from '../../redux/action/dashboard.action';
import CompanyScansTableComponent from './companyScansTable.component';

const CompanyScansTableContainer = () => {
  const companyTable = useSelector((state) => state.dashboard.companyTable.data);
  const isLoading = useSelector((state) => selectLoading(state, fetchListingCompanyTable.typePrefix));
  const error = useSelector((state) => state.dashboard.companyTable.error);

  return (
    <CompanyScansTableComponent
      data={companyTable}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default CompanyScansTableContainer;
