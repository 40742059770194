import React from 'react';
import { getCode } from 'iso-3166-1-alpha-2';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import { ReactCountryFlag } from 'react-country-flag';

const ListingCountryTableComponent = ({ data }) => {
  // Prepare data for the table
  const tableData = data ? data.map((item, index) => ({
    no: index + 1,
    country: item.country,
    scanCount: item.totalCount,
    suspendedCount: item.totalSuspended,
  })) : [];
  tableData?.sort((a, b) => b.scanCount - a.scanCount);

  // Get country code based on country name
  const getCountryCode = (country) => {
    const code = getCode(country);
    return code ? code.toUpperCase() : '';
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        maxHeight: '500px',
        overflow: 'auto',
        p: 2,
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bolder' }}>Country Total Scan Count vs Suspended QR</Typography>
      <TableContainer component={Box} border={1} borderColor="grey.500" borderRadius={1} p={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Country</TableCell>
              <TableCell align="center">Scan Count</TableCell>
              <TableCell align="center">Suspended Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, i) => (
              <TableRow key={item.no}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  <ReactCountryFlag countryCode={getCountryCode(item.country)} svg />
                  {' '}
                  {item.country}
                </TableCell>
                <TableCell align="center">{item.scanCount}</TableCell>
                <TableCell align="center">{item.suspendedCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListingCountryTableComponent;
