import React from 'react';
import { useSelector } from 'react-redux';
import { fetchLineChartScanCount } from 'src/modules/dashboard/redux/action/dashboard.action';
import { selectLoading } from 'src/modules/notification/redux';
import LineChartCardComponent from './lineChartCard.component';

const LineChartCardContainer = () => {
  const lineChartData = useSelector((state) => state.dashboard.lineChartData.data);
  const isLoading = useSelector((state) => selectLoading(state, fetchLineChartScanCount.typePrefix));
  const error = useSelector((state) => state.dashboard.lineChartData.error);

  return (
    <LineChartCardComponent
      data={lineChartData}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default LineChartCardContainer;
