import React from 'react';
import { useSelector } from 'react-redux';
import { fetchListingCountryTable } from 'src/modules/dashboard/redux/action/dashboard.action';
import { selectLoading } from 'src/modules/notification/redux';
import ListingCountryTableComponent from './listingCountryTable.component';

const ListingCountryTableContainer = () => {
  const table = useSelector((state) => state.dashboard.table.data);
  const isLoading = useSelector((state) => selectLoading(state, fetchListingCountryTable.typePrefix));
  const error = useSelector((state) => state.dashboard.table.error);

  return (
    <ListingCountryTableComponent
      data={table}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default ListingCountryTableContainer;
